



@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap');
/* @import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700&display=swap'); */

html {
  font-size: 16px;
  /* font-family: 'Poppins', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
}

/* FONT */
/* div, input, textarea, input:focus, label, select, p, h1, h2, h3, h4 {
  font-family: 'Poppins', 'Quicksand', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
} */
/* body{
  font-family: 'Exo', sans-serif;
} */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-transform:capitalize; */
}

#root {
  display: flex; flex: 1; flex-direction: column;
  min-height: 100vh;
}


div {
  text-align: left;
}

.youTubeVideo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

p, input, textarea {
  font-size: 1.125rem;
  line-height: 1.5;
  background-color:unset;
}

.App {  text-align: center }

/* h1 { font-weight:600; color:#D50000; font-size:3.5rem  } */
/* .divX { display:flex; flex-direction:column; flex:1; min-height:100vh } */
/* .div0 { display:flex; min-height:85vh; align-items:center; justify-content:center; padding-bottom:200px } */
/* .div1 { background-color:white; padding:1rem; display:flex; flex-direction:column; min-height:85vh; padding-bottom:200px } */
.but1 { padding:10px; color:white; border:none; background-color:#D50000 }
.but2 { padding:10px; color:#D50000; border:none; background-color:rgb(243, 226, 226) }

.bgred    { background-color: red}
.bgwhite  { background-color: white}

.flex     { display:flex; flex:1 }
.row      { flex-direction:row }
.ellipsis { overflow:hidden; text-overflow:ellipsis }

input, textarea {
  padding:30;
  border-width:0;
  display:flex; flex:1; 
  border-width:0;
  text-align:'left';
  overflow:hidden; text-overflow:ellipsis;
  -webkit-appearance: none;
}

input:focus {
  outline: none !important;
  border:0px solid #D50000;
  box-shadow: 0 0 0px white;
  box-shadow:none;
}

.input { margin-top:10px; padding:10px; background-color:white; border-width:0px; border-color:#f5f5f5 }

.input:focus {
  outline: none !important;
}

.input:focus-within {
  border: none;
  border: 0px solid green;
  color: black;
}

.searchInput {
  font-size: 1.5rem;
  padding:20px;
  font-weight: bold;
  color: black;
}

.input::-webkit-input-placeholder   { color: #bfbfbf; font-weight:bold }
.input::-moz-placeholder            { color: #bfbfbf; font-weight:bold }
.input:-ms-input-placeholder        { color: #bfbfbf; font-weight:bold }
.input::placeholder                 { color: #bfbfbf; font-weight:bold }
::placeholder                       { color: #bfbfbf; font-weight:bold }

/* Color Stuff */
input[type="color"]:first-child { padding:0; margin:0; border:none; background:none; box-shadow:rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px }
input[type="color"]::-webkit-color-swatch-wrapper { padding:0 }
input[type="color"]::-webkit-color-swatch { border:2px solid white; border-radius:4px }
input[type="color"]:nth-child(2) { padding:0; margin:0; border:none; box-shadow:none; border-radius: 100px; background:#f0bc12; outline:none; }
input[type="color" i] { border-radius:4px; border: none; height:35px; width:35px; display:block; }

input.MuiInputBase-input.MuiOutlinedInput-input {
  font-size: 25px;
  text-align: center;
  font-weight: bold;
}

textarea:focus {
  outline: none !important;
  /* border:1px solid red; */
  /* box-shadow: 0 0 10px #719ECE; */
}

button {
  outline: none;
  border-width:0;
  background-color:transparent;
}

button::-moz-focus-inner {
  border: none;
}

button:focus {
  outline: none;
  /* box-shadow: 0 0 10px grey; */
  /* background-color:black; */
  /* opacity: 0.5; */
}

select {
  border-width: 0;
  background-color: transparent;
  /* removes shadow in safari */
  -webkit-appearance: none; 
}

select:focus {
  outline: none;
  border-width: 0;
}

image {
  size: cover
}

/* // Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {  }

/* // Medium devices (tablets, 768px and up) */

@media (min-width: 768px) { 
  /* .div1 {  align-items:center } */
  /* .div2 {  width:400px; align-self: center; display:flex; flex-direction:column; padding-bottom:300px; } */
  /* .div3 {  width:750px; align-self: center; display:flex; flex-direction:column; padding-bottom:300px; } */
}

/* // Large devices (desktops, 992px and up) */

@media (min-width: 992px) {  }

/* // Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) { }



.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.field {
  border:none;
  border-bottom: solid; border-bottom-color:grey; border-bottom-width: 1;
  outline:none;

  display: flex;
  width: 100%;
  /* box-sizing: 'border-box'; */
  font-size: 18px;
  font-weight: 700;
  padding:12px;
  color:grey;
  /* background-color: rgba(255,255,255,0.1); */
  background-color: black;
  caret-color: #ffd231;

}

.field:focus {
  /* background-color: rgba(255, 255, 255, 0.45); */
  /* box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05); */
  /* background-color: rgba(255,255,255,0.15); */
  border:none;
  border-bottom: solid; border-bottom-color:grey; border-bottom-width: 1;
  outline:none;
}

.buttonCont {
  display: flex;
  justify-content: space-between
}

.button {
  display: flex;
  border-width: 0;
  /* box-sizing: 'border-box'; */
  font-size: 14px;
  background-color: transparent;
  border: 'none';
  /* border-bottom: 'solid'; border-color:'grey'; border-width: 0.5; */
  /* padding:12px; */
  border-radius: 2px;
  font-weight: 700;
  cursor: 'pointer';
  color:rgba(255,255,255,0.3);
  border: none;
  outline:none;

}

.x {
  color:#ffd231;
}

.button:focus {
  /* background-color: rgba(255, 255, 255, 0.45); */
  /* box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05); */
  outline: none;
}

code { font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace }


/* Loading Indicator */

.container {
  align-items: center;
  /* background-color: white; */
  display: flex;
  /* height: 100vh; */
  justify-content: center;
  /* width: 100vw; */
}

.loading {
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
  background-color: #ffd231;
  border-radius: 100%;
  height: 3em;
  width: 3em;
}



@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
    transform: scale(1.0);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button.mapboxgl-popup-close-button {
  display: none;
}

.mapCont div {
  border-radius:5px;
}

input[type="file"] {
  display: none;
}

/* Squares Background */

@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

*{
    margin: 0px;
    padding: 0px;
}



.context {
    width: 100%;
    position: absolute;
    top:50vh;
    
}

.context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
}


.area{
    /* background: #4e54c8;     */
    /* background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8); */
    width: 100%;
    height:100vh;
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    /* background: rgba(255, 255, 255, 0.2); */
    background: #f0808061;
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

/* End background Circles */


/* No Select  */
/* check that input fields are selected on ios simulator before using this */
/* * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
} */




.button1 {
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 
      0px 3px rgba(128,128,128,1), /* gradient effects */
      0px 4px rgba(118,118,118,1),
      0px 5px rgba(108,108,108,1),
      0px 6px rgba(98,98,98,1),
      0px 7px rgba(88,88,88,1),
      0px 8px rgba(78,78,78,1),
      0px 14px 6px -1px rgba(128,128,128,1); /* shadow */
  transition: -webkit-box-shadow .1s ease-in-out;
} 

.button1 span {
  background-color: #E8E8E8;
  
  background-image: 
      /* gloss gradient */
      -webkit-gradient(
          linear, 
          left bottom, 
          left top, 
          color-stop(50%,rgba(255,255,255,0)), 
          color-stop(50%,rgba(255,255,255,0.3)), 
          color-stop(100%,rgba(255,255,255,0.2))),
      
      /* dark outside gradient */
      -webkit-gradient(
          linear, 
          left top, 
          right top, 
          color-stop(0%,rgba(210,210,210,0.3)), 
          color-stop(20%,rgba(210,210,210,0)), 
          color-stop(80%,rgba(210,210,210,0)), 
          color-stop(100%,rgba(210,210,210,0.3))),
      
      /* light inner gradient */
      -webkit-gradient(
          linear, 
          left top, 
          right top, 
          color-stop(0%,rgba(255,255,255,0)), 
          color-stop(20%,rgba(255,255,255,0.5)), 
          color-stop(80%,rgba(255,255,255,0.5)), 
          color-stop(100%,rgba(255,255,255,0))),        
      
      /* diagonal line pattern */
      -webkit-gradient(
          linear, 
          0% 100%, 
          100% 0%, 
          color-stop(0%,rgba(255,255,255,0)), 
          color-stop(40%,rgba(255,255,255,0)), 
          color-stop(40%,#D2D2D1), 
          color-stop(60%,#D2D2D1), 
          color-stop(60%,rgba(255,255,255,0)), 
          color-stop(100%,rgba(255,255,255,0)));
  
      -webkit-box-shadow:
          0px -1px #fff, /* top highlight */
          0px 1px 1px #FFFFFF; /* bottom edge */
  
  -webkit-background-size: 100%, 100%, 100%, 4px 4px;
  
  -webkit-border-radius: 10px;
  -webkit-transition: -webkit-transform .1s ease-in-out;
  
  display: inline-block;
  padding: 10px 40px 10px 40px;
  
  color: #3A474D;
  text-transform: uppercase;
  font-family: 'TradeGothicLTStd-BdCn20','PT Sans Narrow';
  font-weight: 700;
  font-size: 32px;
  
  text-shadow: 0px 1px #fff, 0px -1px #262F33;
}

      .button1 span:hover {
          color: #AEBF3B;
          text-shadow: 0px -1px #97A63A;
          cursor: pointer;
      }

      .button1:active {
          -webkit-box-shadow: 
              0px 3px rgba(128,128,128,1),
              0px 4px rgba(118,118,118,1),
              0px 5px rgba(108,108,108,1),
              0px 6px rgba(98,98,98,1),
              0px 7px rgba(88,88,88,1),
              0px 8px rgba(78,78,78,1),
              0px 10px 2px 0px rgba(128,128,128,.6); /* shadow */
      }

      .button1:active span{
          -webkit-transform: translate(0, 5px); /* depth of button press */
      }

  .button1 span:after {
      content: ">";
      display: block;
      width: 10px;
      height: 10px;
  
      position: absolute;
      right: 14px;
      top: 12px;    
      
      font-family: 'Cabin';
      font-weight: 700;
      color: #AEBF3B;
      text-shadow: 0px 1px #fff, 0px -1px #97A63A;
      font-size: 26px;
  }


.button2 {
  border-radius: 10px;
  box-shadow: 0px 3px rgba(128,128,128,1), 0px 4px rgba(118,118,118,1), 0px 5px rgba(108,108,108,1), 0px 6px rgba(98,98,98,1), 0px 7px rgba(88,88,88,1), 0px 8px rgba(78,78,78,1), 0px 14px 6px -1px rgba(128,128,128,1);
  transition: -webkit-box-shadow .05s ease-in-out;
  margin:9px;
  margin-top:6px;
} 
.button2:active {
  box-shadow: 0px 3px rgba(128,128,128,1),0px 4px rgba(118,118,118,1),0px 5px rgba(108,108,108,1),0px 6px rgba(98,98,98,1),0px 7px rgba(88,88,88,1),0px 8px rgba(78,78,78,1),0px 10px 2px 0px rgba(128,128,128,.6);
}

.button2 .inner {
  background-color: #E8E8E8;
  box-shadow:0px -1px #ffffff57, 0px 1px 1px #ffffff8c;
  background-size: 100%, 100%, 100%, 4px 4px;
  border-radius: 10px;
  transition: .1s ease-in-out;
  /* padding: 0.5rem; */
  min-height:36px;
  /* background: linear-gradient(45deg, rgba(0,0,0,.1), rgba(255,0,0,0) 10%),linear-gradient(135deg, rgba(0,0,0,.1), rgba(255,0,0,0) 10%),linear-gradient(215deg, rgba(0,0,0,.1), rgba(255,0,0,0) 10%),linear-gradient(305deg, rgba(0,0,0,.1), rgba(255,0,0,0) 10%); */
  background-image: 
    /* dark outside gradient */
    -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(210,210,210,0.3)), color-stop(20%,rgba(210,210,210,0)), color-stop(80%,rgba(210,210,210,0)), color-stop(100%,rgba(210,210,210,0.3))),
    /* light inner gradient */
    -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(20%,rgba(255,255,255,0.5)), color-stop(80%,rgba(255,255,255,0.5)), color-stop(100%,rgba(255,255,255,0)))


}

.button2:active .inner {
    transform: translate(0, 5px); /* depth of button press */
}

/* Switch */

span .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color:green ;
}



/* Stripe Card Element */
#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  max-height: 44px;
  width: 100%;
  background: transparent;
  box-sizing: border-box;
}

/* SCROLLBAR */
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  height:0;
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  /* background: #FF0000; */
}

/* header, .navButton { box-shadow:none !important } */

/* TW FIXES */
input.block {
  padding-left: 0.5rem;
}